<template>
  <div>
      <div class="demo">
        <div v-for="(item, index) in list" :key="index">
          <h3 style="margin-top: 5px;margin-left: 85px">{{item.name}}<a-button type="primary" style="margin-left: 15px" @click="linkMaster">操作</a-button></h3>
          <video
              class="hover"
              :id="'video' + item.id"
              controls = "true"
              :width="720"
              :height="480"
              display="flex"
              style="margin-left: 5px">
          </video>
        </div>
      </div>
  </div>
</template>
<script>
import flvjs from "flv.js/dist/flv.js"
import {master_link} from "A/thjlcx";
export default {
  name: "test",
  data() {
    return {
      flvPlayer:null,
      videoUrl:'http://',
      videoElement:'',
      list: [
        {
          src: "http://video-mainserver.u-lake.com:11583/live/001.flv",
          name:"南京保利中心监控1-视频监控",
          id: 0,
        },
        {
          src: "http://video-mainserver.u-lake.com:11583/live/002.flv",
          name:"南京保利中心监控2-道闸监控",
          id: 1,
        },
      ],
    }
  },
  destroyed() {
    this.flv_destroy()
  },
  mounted() {
    this.preview();
  },
  created() {
  },
  methods:{
    linkMaster(){
      master_link({})
    },
    initPlayer(){
      this.list.forEach((item, index) => {
        if (flvjs.isSupported()) {
          this.videoElement = document.getElementById('video'+item.id);
          this.flvPlayer = flvjs.createPlayer({
            type: 'flv',
            url: item.src
          });
          this.flvPlayer.attachMediaElement(this.videoElement);
          this.flvPlayer.load();
          this.flvPlayer.play();
        }
      })
    },
    preview(){
      let that = this;
      setTimeout(function () {
        that.initPlayer();
      }, 1000);
    },
    flv_destroy() {
      console.log("注销播放器组件")
      this.flvPlayer.pause()
      this.flvPlayer.unload()
      this.flvPlayer.detachMediaElement()
      this.flvPlayer.destroy()
      this.flvPlayer = null
    },
  },
}
</script>
<style scoped>
.demo{
  display:flex;
  flex-wrap:wrap;
  overflow: hidden;
  background: #fff;
  position: absolute;
}

.demo:hover{
  margin-left: 0px;
}
</style>